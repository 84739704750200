/* Code is adapted from https://codepen.io/ekeric13/pen/wKOwmg to serve the section: home page school logo carousel.
If too confusing to debug or reuse, consider throwing it away.
*/

const buildQuerySelector = (ancestor, child) => {
  return ancestor + ' ' + child;
};

/* 
args
totalSlidesCount - int. Example: 8
autoMoveDurationMiliseconds - number, in miliseconds. Example: 3000
wraperHtmlSelector - string, CSS selector for the section that contains the carousel. It is used to narrow down where the JS code would apply. If empty, the JS code here will do nothing. Example: "#myid", ".myclass"
*/
const initCarouselSchoolLogos = (
  wrapperHtmlSelector,
  totalSlidesCount,
  autoMoveDurationMiliseconds
) => {
  if (!wrapperHtmlSelector) {
    console.warn('!wraperHtmlSelector. Halt');
    return;
  }

  const wrapperElement = document.querySelector(wrapperHtmlSelector);

  if (!wrapperElement) {
    console.warn('!wrapperElement. Halt');
    return;
  }

  let positionNow = 1;
  var carousel = document.querySelector(buildQuerySelector(wrapperHtmlSelector, '.carousel'));
  var carouselContent = document.querySelector(
    buildQuerySelector(wrapperHtmlSelector, '.carousel-content')
  );
  var slides = document.querySelectorAll(
    buildQuerySelector(wrapperHtmlSelector, '.carousel-content__slide')
  );
  var arrayOfSlides = Array.prototype.slice.call(slides);
  var carouselDisplaying;
  var lengthOfSlide;

  setScreenSize();

  function addClone() {
    var lastSlide = carouselContent.lastElementChild.cloneNode(true);
    lastSlide.style.left = -lengthOfSlide + 'px';
    carouselContent.insertBefore(lastSlide, carouselContent.firstChild);
  }

  function removeClone() {
    var firstSlide = carouselContent.firstElementChild;
    firstSlide.parentNode.removeChild(firstSlide);
  }

  function moveSlidesRight() {
    var slides = document.querySelectorAll(
      buildQuerySelector(wrapperHtmlSelector, '.carousel-content__slide')
    );

    var slidesArray = Array.prototype.slice.call(slides);
    var width = 0;

    slidesArray.forEach(function (el, i) {
      el.style.left = width + 'px';
      width += lengthOfSlide;
    });
    addClone();
  }

  moveSlidesRight();

  function moveSlidesLeft() {
    var slides = document.querySelectorAll(
      buildQuerySelector(wrapperHtmlSelector, '.carousel-content__slide')
    );
    var slidesArray = Array.prototype.slice.call(slides);
    slidesArray = slidesArray.reverse();
    var maxWidth = (slidesArray.length - 1) * lengthOfSlide;

    slidesArray.forEach(function (el, i) {
      maxWidth -= lengthOfSlide;
      el.style.left = maxWidth + 'px';
    });
  }

  window.addEventListener('resize', setScreenSize);

  function setScreenSize() {
    if (window.innerWidth >= 1259) {
      carouselDisplaying = 5;
    } else if (window.innerWidth >= 770) {
      carouselDisplaying = 3;
    } else if (window.innerWidth >= 500) {
      carouselDisplaying = 2;
    } else {
      carouselDisplaying = 1;
    }

    getScreenSize();
  }

  function getScreenSize() {
    var slides = document.querySelectorAll(
      buildQuerySelector(wrapperHtmlSelector, '.carousel-content__slide')
    );

    var slidesArray = Array.prototype.slice.call(slides);
    lengthOfSlide = carousel.offsetWidth / carouselDisplaying;
    var initialWidth = -lengthOfSlide;
    slidesArray.forEach(function (el) {
      el.style.width = lengthOfSlide + 'px';
      el.style.left = initialWidth + 'px';
      initialWidth += lengthOfSlide;
    });
  }

  var moving = true;
  function moveRight() {
    if (moving) {
      moving = false;
      var lastSlide = carouselContent.lastElementChild;
      lastSlide.parentNode.removeChild(lastSlide);
      carouselContent.insertBefore(lastSlide, carouselContent.firstChild);
      removeClone();
      var firstSlide = carouselContent.firstElementChild;
      firstSlide.addEventListener('transitionend', activateAgain);
      moveSlidesRight();
      indicateDot(positionNow, false);
    }
  }

  function activateAgain() {
    var firstSlide = carouselContent.firstElementChild;
    moving = true;
    firstSlide.removeEventListener('transitionend', activateAgain);
  }

  const getDotIdByPosition = (position) => {
    return `dot_${position}_js`;
  };

  const toggleDot = (position, makeActive) => {
    const dot = document.getElementById(getDotIdByPosition(position));

    if (!dot) {
      return;
    }

    const activeCls = 'carousel-dots-box__item--active';
    if (makeActive) {
      dot.classList.add(activeCls);
    } else {
      dot.classList.remove(activeCls);
    }
  };

  const indicateDot = (oldPosition, isIncreasing) => {
    let newPosition;
    if (isIncreasing) {
      newPosition = oldPosition >= totalSlidesCount ? 1 : oldPosition + 1;
    } else {
      newPosition = oldPosition <= 1 ? totalSlidesCount : oldPosition - 1;
    }

    toggleDot(oldPosition, false);
    toggleDot(newPosition, true);

    positionNow = newPosition;
  };

  function moveLeft() {
    if (moving) {
      moving = false;
      removeClone();
      var firstSlide = carouselContent.firstElementChild;
      firstSlide.addEventListener('transitionend', replaceToEnd);
      moveSlidesLeft();
      indicateDot(positionNow, true);
    }
  }

  function replaceToEnd() {
    var firstSlide = carouselContent.firstElementChild;
    firstSlide.parentNode.removeChild(firstSlide);
    carouselContent.appendChild(firstSlide);
    firstSlide.style.left = (arrayOfSlides.length - 1) * lengthOfSlide + 'px';
    addClone();
    moving = true;
    firstSlide.removeEventListener('transitionend', replaceToEnd);
  }

  const initAutoMove = () => {
    if (autoMoveDurationMiliseconds > 0) {
      setInterval(moveLeft, autoMoveDurationMiliseconds);
    }
  };

  initAutoMove();

  document
    .querySelector(buildQuerySelector(wrapperHtmlSelector, '.nav-box__left'))
    .addEventListener('click', moveRight);

  document
    .querySelector(buildQuerySelector(wrapperHtmlSelector, '.nav-box__right'))
    .addEventListener('click', moveLeft);
};

export default initCarouselSchoolLogos;
